
import Footer from "@/components/Footer.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "PhotoGallery",
  components: {
    Footer
  }
})
export default class PhotoGallery extends Vue {
  index = null;
  images: any = {};
  thumbnails: any = {};
  selectedCategory = "";

  mounted() {
    this.importAll(
      require.context("../assets/photo-gallery/unirii_webp/", true, /\.webp$/),
      "Clinica Unirii"
    );
    this.importAll(
      require.context("../assets/photo-gallery/basarabia_webp/", true, /\.webp$/),
      "Cabinet Lia Manoliu"
    );
    this.importThumbnails(
      require.context("../assets/photo-gallery/unirii_thumbnail_webp/", true, /\.webp$/),
      "Clinica Unirii"
    );
    this.importThumbnails(
      require.context("../assets/photo-gallery/basarabia_thumbnail_webp/", true, /\.webp$/),
      "Cabinet Lia Manoliu"
    );
  }

  public importAll(r, category) {
    if (this.images[category] === undefined) {
      this.images = { ...this.images, [category]: [] };
    }
    r.keys().forEach(key => this.images[category].push(r(key)));
  }

  public importThumbnails(r, category) {
    if (this.thumbnails[category] === undefined) {
      this.thumbnails = { ...this.thumbnails, [category]: [] };
    }
    r.keys().forEach(key => this.thumbnails[category].push(r(key)));
  }

  public get filteredImages() {
    if (!this.selectedCategory) {
      return Object.values(this.images).reduce(
        (accumulator: string[], categoryImages) => {
          return [...accumulator, ...(categoryImages as string[])];
        },
        []
      ).filter((imagePath: string) => {
          const splittedPath = imagePath.split('/');
          const imageName = splittedPath[splittedPath.length - 1];
          const splittedName = imageName.split('.');
          splittedName.splice(splittedName.length - 2, 2);
          const imageRealName = splittedName.join('.');
          return Object.values(this.thumbnails).some((thumbnailCategory: any) => thumbnailCategory.find(thumbnail => thumbnail.includes(imageRealName)));
        });
    } else {
      return this.images[this.selectedCategory]
        .filter((imagePath: string) => {
          const splittedPath = imagePath.split('/');
          const imageName = splittedPath[splittedPath.length - 1];
          const splittedName = imageName.split('.');
          splittedName.splice(splittedName.length - 2, 2);
          const imageRealName = splittedName.join('.');
          return this.thumbnails[this.selectedCategory].find(thumbnail => thumbnail.includes(imageRealName));
        });
    }
  }

  public get filteredThumbnails() {
    if (!this.selectedCategory) {
      return Object.values(this.thumbnails).reduce(
        (accumulator: string[], categoryImages) => {
          return [...accumulator, ...(categoryImages as string[])];
        },
        []
      );
    } else {
      return this.thumbnails[this.selectedCategory];
    }
  }

  public get categories() {
    return Object.keys(this.images);
  }
}
